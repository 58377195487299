import { Outlet, useLoaderData } from '@remix-run/react'
import type { LoaderFunctionArgs } from '@remix-run/server-runtime'
import { useState } from 'react'

import { Toaster } from 'sonner'
import UserSessionContext from '~/core/session/contexts/user-session'
import type UserSession from '~/core/session/types/user-session'
import SiteHeader from '~/components/SiteHeader'
import Footer from '~/components/Footer'

import loadUserData from '~/lib/server/loaders/load-user-data.server'
import AuthChangeListener from '~/components/AuthChangeListener'

export const loader = (args: LoaderFunctionArgs) => loadUserData(args)

function SiteLayout() {
  const { userSession, setUserSession, accessToken } = useSiteLayoutData()

  return (
    <UserSessionContext.Provider value={{ userSession, setUserSession }}>
      <AuthChangeListener accessToken={accessToken}>
        <SiteHeader />
        <Toaster richColors={false} position="top-center" />

        <main>
          <Outlet />
        </main>

        <Footer />
      </AuthChangeListener>
    </UserSessionContext.Provider>
  )
}

function useSiteLayoutData() {
  const loaderData = useLoaderData<typeof loadUserData>()

  const [userSession, setUserSession] = useState<Maybe<UserSession>>({
    data: loaderData.data || undefined,
    auth: loaderData.auth || undefined,
    role: undefined,
  })

  const accessToken = loaderData.auth?.accessToken

  return {
    userSession,
    setUserSession,
    accessToken,
  }
}

export default SiteLayout
